.backgroundImage {
  height: 100vh;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.8))), url("../Images/tech13.jpg");
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url("../Images/tech13.jpg");
  background-size: cover;
  background-position: center center;
  background-size: cover;
}

.backgroundImage2 {
  height: 100vh;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.8))), url("../Images/tech14.jpg");
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url("../Images/tech14.jpg");
  background-size: cover;
  background-position: center center;
  background-size: cover;
}

.landingPageContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100vh;
}

.name {
  color: white;
  font-size: 5rem;
  font-weight: 400;
  text-align: center;
}

.button-links-row {
  display: row;
}
