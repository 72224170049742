.flex-wrap-custom {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
}

.project-name {
  color: #E53935;
  font-size: 1.8rem;
  font-weight: 600;
}

.project-card-border {
  margin-right: 1rem;
  margin-bottom: .5rem;
  border-radius: 4px;
  border: 2px solid #e53935;
}

.project-image {
  max-width: 50%;
  max-width: 150px;
  max-height: 150px;
}

.project-card-margin {
  margin-left: .5rem;
  margin-right: .5rem;
}

.project-link {
  color: white !important;
  border-radius: 10px;
  padding-top: .4rem;
  padding-bottom: .1rem;
  max-width: 80%;
}

.demoLink {
  background-color: #e53935;
  color: white !important;
}

.externalLinkPadding {
  margin-bottom: 4px;
  margin-left: 4px;
}

.viewSourceText {
  color: #e53935;
}

@media screen and (min-width: 768px) {
  .project-image {
    max-width: 100%;
  }
  .project-card-margin {
    margin-left: -15px;
    margin-right: -15px;
  }
}
