.freelance-title {
  text-align: center;
  font-size: 2rem;
}

.freelance-link {
  color: #E53935;
  font-size: 2rem;
}

.freelance-link:hover {
  color: #E53935;
}

@media screen and (min-width: 576px) {
  .freelance-title {
    font-size: 3rem;
  }
}
