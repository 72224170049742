.about-title {
  color: #E53935;
  font-size: 2rem;
  font-weight: bold;
  text-decoration: underline;
}

.tech-font {
  font-size: 1.2rem;
  color: #E53935;
}
