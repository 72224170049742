.link-items {
  color: white !important;
  padding: .5rem 0rem !important;
}

.navbar-icons {
  width: 30px;
  height: 30px;
  -o-object-fit: contain;
     object-fit: contain;
  padding-right: 6px;
}

.navbar-link-text {
  color: #e53935 !important;
  font-weight: bold;
}

@media screen and (min-width: 992px) {
  .link-items {
    margin-left: 1rem;
  }
}
