.experienceContainer {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto-fill;
      grid-template-rows: auto-fill;
}

.experienceContainer .title {
  padding-top: 2vh;
  font-size: 5vh;
  font-weight: 100;
  text-align: center;
}

.experienceLayout {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
}

.card {
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  width: 100%;
}

.card:hover {
  -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.container8 {
  padding: 2px 16px;
}
